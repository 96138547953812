import { gql } from '@apollo/client'

export const MERGE_CUSTOMERS_MUTATION = gql`
  mutation mergeCustomers($id: ID!, $mergeIds: [ID!]!) {
    mergeCustomers(id: $id, mergeIds: $mergeIds)
  }
`
export const DELETE_UPLOAD_MUTATION = gql`
  mutation deleteUpload($id: ID!) {
    deleteUpload(id: $id)
  }
`

export const CREATE_UPLOAD_MUTATION = gql`
  mutation createUpload($attributes: UploadInput!) {
    createUpload(attributes: $attributes) {
      createdAt
      updatedAt
      attachmentUrl
      attachmentFilename
    }
  }
`

export const CREATE_TASK_MUTATION = gql`
  mutation createTask($attributes: TaskInput!) {
    createTask(attributes: $attributes) {
      createdAt
      done
      dueDate
      id
      processingId
      title
      updatedAt
    }
  }
`

export const UPDATE_TASK_MUTATION = gql`
  mutation updateTask($id: ID!, $attributes: TaskInput!) {
    updateTask(id: $id, attributes: $attributes) {
      createdAt
      done
      dueDate
      id
      processingId
      title
      updatedAt
    }
  }
`

export const CREATE_M2_CASE_MUTATION = gql`
  mutation createM2Case($attributes: M2CaseInput!) {
    createM2Case(attributes: $attributes) {
      id
      policyholder {
        id
        duplicateEmail
        possibleDuplicate
      }
    }
  }
`

export const UPDATE_M2_CASE_MUTATION = gql`
  mutation updateM2Case($id: ID!, $attributes: M2CaseInput!, $shouldNotify: Boolean) {
    updateM2Case(id: $id, attributes: $attributes, shouldNotify: $shouldNotify) {
      id
    }
  }
`

export const CREATE_PROCESSING_MUTATION = gql`
  mutation createProcessing($attributes: ProcessingInput!) {
    createProcessing(attributes: $attributes) {
      id
      acceptedAt
      additionalComments
      additionalCost
      agent {
        id
        name
      }
      alternativeProcess
    }
  }
`
export const CREATE_PAYMENT_MUTATION = gql`
  mutation createPayment($attributes: PaymentInput!) {
    createPayment(attributes: $attributes) {
      amount
      email
      status
    }
  }
`

export const REQUEST_PAYMENT_MUTATION = gql`
  mutation requestPayment($paymentId: ID!) {
    requestPayment(paymentId: $paymentId) {
      stripeCheckoutUrl
    }
  }
`

export const PAYMENT_REMINDER_MUTATION = gql`
  mutation paymentReminder($paymentId: ID!) {
    paymentReminder(paymentId: $paymentId) {
      stripeCheckoutUrl
    }
  }
`

export const REFUND_PAYMENT_MUTATION = gql`
  mutation refundPayment($paymentId: ID!, $amount: Float!) {
    refundPayment(paymentId: $paymentId, amount: $amount) {
      stripeCheckoutUrl
    }
  }
`

export const SELF_ASSIGN_PROCESSING = gql`
  mutation selfAssignProcessing($poolSlug: String!, $id: ID) {
    selfAssignProcessing(poolSlug: $poolSlug, id: $id) {
      id
      m2Case {
        id
      }
    }
  }
`

export const UPDATE_PROCESSING_MUTATION = gql`
  mutation updateProcessing($id: ID!, $attributes: ProcessingInput!) {
    updateProcessing(id: $id, attributes: $attributes) {
      id
      acceptedAt
      additionalComments
      additionalCost
      processingState
      agent {
        id
        name
      }
      alternativeProcess
    }
  }
`

export const DELETE_PROCESSING_MUTATION = gql`
  mutation deleteProcessing($id: ID!) {
    deleteProcessing(id: $id)
  }
`

export const UPDATE_PROCESSINGS_MUTATION = gql`
  mutation updateProcessings($ids: [ID!]!, $attributes: ProcessingInput!) {
    updateProcessings(ids: $ids, attributes: $attributes)
  }
`

export const DELETE_SELECTION_ITEM_MUTATION = gql`
  mutation deleteColumnSelection($id: ID!) {
    deleteColumnSelection(id: $id)
  }
`

export const CREATE_SELECTION_ITEM_MUTATION = gql`
  mutation createColumnSelection($attributes: ColumnSelectionInput!) {
    createColumnSelection(attributes: $attributes) {
      id
      createdAt
      updatedAt
      title
      columns
      user {
        name
      }
    }
  }
`

export const FORWARD_TO_RIGHTMART = gql`
  mutation forwardToRightmart($id: ID!, $attributes: ForwardToRightmartInput!) {
    forwardToRightmart(id: $id, attributes: $attributes)
  }
`

export const REQUEST_COST_COVERAGE = gql`
  mutation requestCostCoverage($id: ID!, $attributes: CostCoverageInput!) {
    requestCostCoverage(id: $id, attributes: $attributes)
  }
`

export const SEND_INITIAL_CONTACT_SMS = gql`
  mutation sendInitialContactSms($caseId: ID!, $smsText: String!) {
    sendInitialContactSms(caseId: $caseId, smsText: $smsText)
  }
`

export const SEND_CONTACT_ATTEMPT_SMS = gql`
  mutation sendContactAttemptSms($processingId: ID!) {
    sendContactAttemptSms(processingId: $processingId)
  }
`

export const CREATE_ACTION_MUTATION = gql`
  mutation createAction($attributes: ActionInput!) {
    createAction(attributes: $attributes) {
      id
      type
      processingId
      note
      visibleForPolicyholder
      visibleForOtherProcessings
      firstCall
      contactType
      informReason
      completeReason
      closingType
      actualProcessingTypeId
      customerRating
      customerComments
      closingResult
      costsCoveredByKp
      costs
      contactSuccessful
      closingTypeOther
      date
      policyholderAgreesMediation
      conflictPartnerAgreesMediation
      conflictPartnerAgreesToCoverCosts
      uploads {
        id
        attachmentUrl
        attachmentFilename
      }
    }
  }
`

export const UPDATE_ACTION_MUTATION = gql`
  mutation updateAction($id: ID!, $attributes: ActionInput!) {
    updateAction(id: $id, attributes: $attributes) {
      hint
      draft
      claimDate
      fileNumber
      closingResult
      closingType
      closingTypeOther
      communicationViaCustomerPortal
      rightActualProcessingType
      rsvLegalArea {
        id
        title
      }
      rsvLegalAreaId
      settlementComments
      actualProcessingType {
        id
        title
        legalAdviceOnly
      }
      actualProcessingTypeId
      intendedProcessingType {
        id
        title
      }
      intendedProcessingTypeId
      contactPersonFirstname
      contactPersonLastname
      contactPersonEmail
      contactPersonPhoneNumber
      contactPersonMobileNumber
      contactPersonRelationToPolicyholder
      policyholderFirstname
      policyholderLastname
      policyholderEmail
      policyholderPlace
      policyholderPostalCode
      policyholderPhoneNumber
      policyholderMobileNumber
      policyNumber
      conflictPartnerCompany
      conflictPartnerSalutation
      conflictPartnerFirstname
      conflictPartnerLastname
      conflictPartnerEmail
      conflictPartnerPhoneNumber
      conflictPartnerPlace
      conflictPartnerStreet
      conflictPartnerHouseNumber
      conflictPartnerMobileNumber
      needsCollisionDetection
      noCollisionByHuman
      completeReason
      conflictPartnerAgreesMediation
      conflictPartnerAgreesToCoverCosts
      contactSuccessful
      contactType
      costs
      costsCoveredByKp
      createdAt
      customerComments
      customerRating
      customerMessageType
      dataset
      date
      firstCall
      id
      informReason
      internalTransferReason
      note
      legalAdvice
      mailSentAt
      attorneyStatus
      noMailSentReason
      attorneyReason
      attorneyType
      policyholderAgreesMediation
      processing {
        agentId
        id
        m2Case {
          id
        }
        involvedUsers {
          id
          name
        }
      }
      processingId
      type
      updatedAt
      user {
        id
        name
        role
      }
      userId
      visibleForOtherProcessings
      visibleForPolicyholder
      uploads {
        id
        attachmentUrl
        attachmentFilename
      }
    }
  }
`
export const DELETE_ACTION_MUTATION = gql`
  mutation deleteAction($id: ID!) {
    deleteAction(id: $id)
  }
`

export const SEND_INVOICE_MUTATION = gql`
  mutation sendInvoice($processingId: ID!) {
    sendInvoice(processingId: $processingId) {
      id
    }
  }
`
export const CREATE_BLOG_POST_MUTATION = gql`
  mutation createBlogPost($attributes: BlogPostInput!) {
    createBlogPost(attributes: $attributes) {
      id
      title
      subtitle
      content
      pinned
      categories
    }
  }
`
export const UPDATE_BLOG_POST_MUTATION = gql`
  mutation updateBlogPost($id: ID!, $attributes: BlogPostInput!) {
    updateBlogPost(id: $id, attributes: $attributes) {
      id
      title
      subtitle
      content
      pinned
      categories
    }
  }
`
export const DELETE_BLOG_POST_MUTATION = gql`
  mutation deleteBlogPost($id: ID!) {
    deleteBlogPost(id: $id)
  }
`

export const UPDATE_USER_MUTATION = gql`
  mutation updateUser($attributes: UserInput!) {
    updateUser(attributes: $attributes) {
      id
      firstname
      lastname
      birthDate
      email
      street
      houseNumber
      place
      postalCode
      mobileNumber
      phoneNumber
      portalEmail
      portalPhoneNumber
      portalMobileNumber
    }
  }
`

export const CREATE_AWAY_NOTIFICATIONS_MUTATION = gql`
  mutation createAwayNotifications {
    createAwayNotifications
  }
`

export const CREATE_LEGAL_ADVICE_CASE = gql`
  mutation createLegalAdviceCase(
    $attributes: M2CaseInput!
    $actionAttributes: ActionInput!
    $editorId: ID!
  ) {
    createLegalAdviceCase(
      attributes: $attributes
      actionAttributes: $actionAttributes
      editorId: $editorId
    ) {
      id
      policyholder {
        id
        duplicateEmail
        possibleDuplicate
      }
    }
  }
`

export const CALL_CUSTOMER_MUTATION = gql`
  mutation callCustomer($processingId: ID!, $attributes: CallCustomerInput!) {
    callCustomer(processingId: $processingId, attributes: $attributes)
  }
`

export const CREATE_COMPANY_MUTATION = gql`
  mutation createCompany($attributes: CompanyInput!) {
    createCompany(attributes: $attributes) {
      id
      title
    }
  }
`
