import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Grid, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Button } from '../../../atoms'

interface FooterProps {
  closeButtonOnly?: () => void
  saveButtonText?: string
  saveButtonIcon?: JSX.Element
  presaveButton?: boolean
  presaveButtonText?: string
  presaveButtonIcon?: JSX.Element
  rightActions?: React.ReactNode
  abortCallback(): void
  formId?: string
  handleSave(): void
  handlePresave?(): void
}

const Footer: React.FC<FooterProps> = ({
  closeButtonOnly,
  saveButtonText,
  saveButtonIcon,
  presaveButton,
  presaveButtonText,
  presaveButtonIcon,
  rightActions,
  abortCallback,
  formId,
  handleSave,
  handlePresave
}) => {
  const { reset } = useFormContext()
  const { t } = useTranslation('common')
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      flexDirection={mobile ? 'column-reverse' : 'row'}
    >
      <Grid
        container
        item
        xs={10}
        md="auto"
        flexDirection={mobile ? 'column' : 'row'}
        sx={{
          [theme.breakpoints.down('lg')]: {
            marginTop: '10px'
          }
        }}
      >
        {closeButtonOnly ? (
          <Grid item>
            <Button id="editor-modal-ok-button" type="button" onClick={closeButtonOnly}>
              {t('common:ok')}
            </Button>
          </Grid>
        ) : (
          <>
            <Grid item>
              <Button
                id="editor-modal-save-button"
                startIcon={saveButtonIcon}
                type="button"
                form={formId ?? 'edit-form'}
                onClick={handleSave}
                sx={{
                  marginRight: '10px',
                  [theme.breakpoints.down('md')]: {
                    marginRight: '0px',
                    marginBottom: '10px'
                  }
                }}
                fullWidth={mobile}
              >
                {saveButtonText ?? t('common:save')}
              </Button>
            </Grid>
            {presaveButton ? (
              <Grid item>
                <Button
                  id="editor-modal-presave-button"
                  startIcon={presaveButtonIcon}
                  type="button"
                  // form={formId ?? 'edit-form'}
                  onClick={handlePresave}
                  sx={{
                    marginRight: '10px',
                    [theme.breakpoints.down('md')]: {
                      marginRight: '0px',
                      marginBottom: '10px'
                    }
                  }}
                  fullWidth={mobile}
                >
                  {presaveButtonText ?? t('common:presave')}
                </Button>
              </Grid>
            ) : null}
            <Grid item>
              <Button
                fullWidth={mobile}
                form={formId ?? 'edit-form'}
                onClick={async () => {
                  await reset()
                  abortCallback()
                }}
              >
                {t('abort')}
              </Button>
            </Grid>
          </>
        )}
      </Grid>
      <Grid
        item
        xs={8}
        md="auto"
        sx={{
          [theme.breakpoints.down('lg')]: {
            marginTop: '10px'
          }
        }}
      >
        {rightActions}
      </Grid>
    </Grid>
  )
}

Footer.defaultProps = {
  formId: undefined,
  saveButtonText: undefined,
  saveButtonIcon: undefined,
  rightActions: null
}

export default Footer
