import React, { useState, useEffect } from 'react'
import {
  FormControl,
  MenuItem,
  InputLabel,
  FormHelperText,
  Divider,
  ListSubheader,
  SxProps
} from '@mui/material'
import { styled, Theme } from '@mui/material/styles'
import { uniqueId } from 'lodash'
import { DownArrow } from '../../../assets/icons'
import CustomSelect, { CustomSelectProps } from './CustomSelect'

export interface Item {
  value: any
  label: string
  isSeperator?: boolean
  isArchived?: boolean
}

interface SelectProps extends Omit<CustomSelectProps, 'component'> {
  italic?: boolean
  items: Item[]
  label?: string
  helperText?: string
  showOnlyField?: { value: any; label?: string }
  onChange?: (value?: any) => void
  onClick?: (value?: any) => void
  sx?: SxProps<Theme>
}

interface StyledFormControlProps extends React.ComponentProps<typeof FormControl> {
  italic?: boolean
}

const StyledFormControl: React.FC<StyledFormControlProps> = styled(FormControl)(
  ({ italic }: StyledFormControlProps) =>
    italic
      ? { '& > .MuiInputLabel-outlined': { fontStyle: 'italic' } }
      : { '& label + .MuiInput-formControl': { marginTop: '12px' } }
)
const Select: React.ForwardRefExoticComponent<
  Omit<SelectProps, 'ref'> & React.RefAttributes<unknown>
> = React.forwardRef((props, ref) => {
  const {
    children,
    disabled,
    italic,
    items,
    label,
    minWidth = 100,
    maxWidth = undefined,
    multiple,
    onChange,
    onClick,
    value: propValue,
    variant,
    helperText,
    native,
    showOnlyField,
    error,
    id,
    sx
  } = props
  const [value, setValue] = useState<unknown>(multiple ? [] : '')

  const renderValue = (selected: any) => {
    if (showOnlyField?.value === selected) {
      return showOnlyField?.label || showOnlyField?.value
    }
    return items?.find(item => item?.value === selected)?.label
  }

  const [open, setOpen] = useState(false)
  useEffect(() => {
    if (propValue !== value) setValue(propValue)
  }, [propValue, setValue, value])

  return (
    <StyledFormControl
      italic={italic}
      variant={variant}
      style={{ minWidth, maxWidth }}
      size="small"
      error={!!error}
      ref={ref as unknown as React.RefObject<HTMLDivElement>}
    >
      <InputLabel disableAnimation htmlFor="select-outlined">
        {label}
      </InputLabel>
      <CustomSelect
        id={id || 'select-outlined'}
        maxWidth={maxWidth}
        minWidth={minWidth}
        multiple={multiple}
        value={value}
        {...(showOnlyField && {
          renderValue
        })}
        onChange={({ target: { value: newValue } }) => onChange?.(newValue)}
        onOpen={() => {
          onClick?.()
          setOpen(true)
        }}
        onClose={() => setOpen(false)}
        open={open}
        label={label}
        disabled={disabled}
        IconComponent={iconProps => <DownArrow {...iconProps} style={{ top: 'inherit' }} />}
        native={native}
        sx={sx}
      >
        {items
          ? [native && { label: '', value: undefined }, ...items]
              .filter(item => !!item && (!item.isArchived || item.value === value))
              .map((item, index) => {
                if (native && item) {
                  return (
                    <option
                      key={item.value}
                      value={item.value}
                      disabled={index === 0}
                      hidden={index === 0}
                      selected={(!value && index === 0) || value === item.value}
                      title={item.label}
                    >
                      {item.label}
                    </option>
                  )
                }
                if ((item as Item)?.isSeperator && index !== 0) {
                  return <ListSubheader component={Divider} key={uniqueId()} />
                }
                return (
                  <MenuItem
                    component={native ? 'option' : 'li'}
                    key={item ? item?.value : uniqueId()}
                    value={item ? item?.value : undefined}
                    sx={{
                      '&:hover': {
                        backgroundColor: '#6b7280',
                        color: 'white'
                      },
                      '&.Mui-selected': {
                        backgroundColor: '#9ca3af',
                        color: 'white'
                      },
                      '&.Mui-selected:hover': {
                        backgroundColor: '#4b5563'
                      }
                    }}
                  >
                    {item ? item?.label : ''}
                  </MenuItem>
                )
              })
          : children}
      </CustomSelect>
      <FormHelperText>{helperText}</FormHelperText>
    </StyledFormControl>
  )
})

export default Select
