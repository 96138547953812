import React, { ForwardedRef } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'
import { Attachment } from '../../../utils/fixtures'
import { resolveNestedObject } from '../../../utils/helper'
import {
  DatePicker,
  DatePickerProps,
  Input,
  Rating,
  Select,
  QuerySelect,
  CheckBox,
  RadioButtonGroup,
  Switch,
  Item,
  Button
} from '../../atoms'
import BillingButton from '../BillingButton'
import CollisionDetection from '../CollisionDetection'
import CompanyAutoComplete from '../CompanyAutoComplete'
import ExportButton from '../ExportButton'
import FilesList from '../FilesList'
import ForwardRightmartButton from '../ForwardRightmartButton'
import RequestCoverageButton from '../RequestCoverageButton'
import SMSButton from '../SMSButton'
import Uploader from '../Uploader'

interface IRadioItems {
  label: string
  value: any
}

interface IValueLabel {
  value: any
  label: string
}

interface IQueryOptions {
  showOnlyField?: IValueLabel
  variables: any
}

interface IOptions {
  onSelected?: (value: any) => void
  onChange?(value: any): void
  onBlur?(value: any): void
  items?: Item[]
  autosize?: boolean
  rows?: number
  url?: string
  children?: React.ReactChildren
  parentSx?: any
  additionalSelectFields?: Item[]
  onClick?: () => void
  icon?: JSX.Element
  loading?: boolean
  subject?: string
  body?: string
  onBlurValidOnly?: boolean
  helperTextColor?: string | undefined
  onSavePreset?: () => void
}

interface IProps {
  name: string
  callback?: () => void
  query?: any
  mutation?: any
  queryOptions?: IQueryOptions
  label?: string
  disabled?: boolean
  helperText?: string
  dialogContent?: any
  onChangeProcessingId?: (value: string | string[]) => void
  options?: IOptions
  radioItems?: IRadioItems[]
  type?:
    | 'time'
    | 'date'
    | 'datetime'
    | 'typography'
    | 'value'
    | 'export'
    | 'text'
    | 'smsButton'
    | 'collisionDetection'
    | 'requestCoverageButton'
    | 'forwardRightmartButton'
    | 'billingButton'
    | 'dropdown'
    | 'select'
    | 'rating'
    | 'boolean'
    | 'textfield'
    | 'checkbox'
    | 'phone'
    | 'radio'
    | 'switch'
    | 'uploader'
    | 'money'
    | 'files'
    | 'default'
    | 'actionButton'
    | 'email'
    | 'companySearch'
}

const FormItem: React.FunctionComponent<IProps> = ({
  query,
  queryOptions,
  mutation,
  label,
  disabled,
  name,
  helperText,
  dialogContent,
  options,
  radioItems,
  type,
  callback,
  onChangeProcessingId = undefined
}) => {
  const { t } = useTranslation(['common', 'validation'])
  const {
    control,
    getFieldState,
    getValues,
    formState: { errors }
  } = useFormContext()

  const handleFieldBlurFromEvent = async (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    onBlur: any
  ) => {
    onBlur?.(e)
    if (options?.onBlurValidOnly) {
      const fieldState = getFieldState(name)

      if (
        (!fieldState?.invalid && fieldState?.isDirty) ||
        (fieldState?.isTouched && getValues(name))
      ) {
        options?.onBlur?.(e)
      }
    } else {
      options?.onBlur?.(e)
    }
  }

  // @ts-ignore
  const handleFieldChangeFromEvent = ({ target: { value } }, onChange) => {
    onChange(value)
    options?.onChange?.(value)
  }
  // @ts-ignore
  const handleFieldChangeFromValue = (value, onChange) => {
    onChange(value)
    options?.onChange?.(value)
  }
  // @ts-ignore
  const handleSwitchChange = ({ target: { checked } }, onChange) => {
    onChange(checked)
    options?.onChange?.(checked)
  }

  const getFilesFromValue = (value?: Attachment[]) =>
    value?.map((file: Attachment) => ({
      ...file,
      filename: file.attachmentFilename,
      url: file.attachmentUrl
    }))

  const filesComponent = (value?: Attachment[]) => {
    const files = getFilesFromValue(value)
    // @ts-ignore
    return <FilesList {...options} files={files} label={label} />
  }

  const dateComponent = (datePickerProps: DatePickerProps, ref: ForwardedRef<JSX.Element>) => (
    <DatePicker
      {...options}
      ref={ref}
      onChange={e => handleFieldChangeFromValue(e, datePickerProps.onChange)}
      value={datePickerProps.value}
      label={label}
      type={datePickerProps.type}
      fullWidth
      disabled={disabled}
      helperText={helperText}
    />
  )
  // @ts-ignore
  const dropdownComponent = (onChange, value, ref: ForwardedRef<JSX.Element>) => (
    <QuerySelect
      id={name}
      ref={ref}
      // @ts-ignore
      onChange={e => handleFieldChangeFromValue(e, onChange)}
      // @ts-ignore
      value={value}
      error={resolveNestedObject(name, errors)}
      label={label}
      // @ts-ignore
      name={queryOptions?.variables?.filter?.lookupType || undefined}
      query={query}
      options={queryOptions}
      additionalSelectFields={options?.additionalSelectFields}
      disabled={disabled}
      minWidth="100%"
      helperText={t(resolveNestedObject(name, errors)?.message) || helperText}
      showOnlyField={queryOptions?.showOnlyField}
    />
  )
  // @ts-ignore
  const selectComponent = (onChange, value, ref: ForwardedRef<JSX.Element>) => (
    <Select
      id={name}
      ref={ref}
      // @ts-ignore
      onChange={e => handleFieldChangeFromValue(e, onChange)}
      // @ts-ignore
      value={value}
      error={resolveNestedObject(name, errors)}
      label={label}
      // @ts-ignore
      name={queryOptions?.variables?.filter?.lookupType || undefined}
      disabled={disabled}
      minWidth="100%"
      items={options?.items || []}
      helperText={t(resolveNestedObject(name, errors)?.message) || helperText}
      showOnlyField={queryOptions?.showOnlyField}
    />
  )
  // const dropdownComponent = (
  //   querySelectProps: QuerySelectProps,
  //   ref: ForwardedRef<JSX.Element>
  // ) => (
  //   <QuerySelect
  //     ref={ref}
  //     // @ts-ignore
  //     onChange={e => handleFieldChangeFromValue(e, querySelectProps.onChange)}
  //     value={querySelectProps.value}
  //     label={label}
  //     // @ts-ignore
  //     name={queryOptions?.variables?.filter?.lookupType || undefined}
  //     query={query}
  //     options={queryOptions}
  //     disabled={disabled}
  //     // helperText={helperText}
  //     // showOnlyField={queryOptions?.showOnlyField}
  //   />
  // )
  // @ts-ignore
  const ratingComponent = (onChange, value, ref) => (
    <Rating
      id={name}
      ref={ref}
      // @ts-ignore
      value={value}
      // @ts-ignore
      onChange={val => handleFieldChangeFromValue(val, onChange)}
      disabled={disabled}
      helperText={t(resolveNestedObject(name, errors)?.message) || helperText}
      error={resolveNestedObject(name, errors)}
    />
  )

  const booleanComponent = (onChange: any, value: any, ref: any) => (
    <Select
      id={name}
      ref={ref}
      value={value}
      onChange={val => handleFieldChangeFromValue(val, onChange)}
      minWidth="100%"
      label={label}
      items={[
        {
          label: t('common:yes'),
          value: true
        },
        {
          label: t('common:no'),
          value: false
        }
      ]}
      disabled={disabled}
      helperText={helperText}
    />
  )
  // @ts-ignore
  const textfieldComponent = (onBlur, onChange, value, ref) => (
    <Input
      {...options}
      id={name}
      noPlaceholder
      ref={ref}
      value={value}
      onChange={e => handleFieldChangeFromEvent(e, onChange)}
      onBlur={e => handleFieldBlurFromEvent(e, onBlur)}
      multiline
      // @ts-ignore
      rows={options?.autosize ? undefined : options?.rows || 5}
      fullWidth
      // @ts-ignore
      type={type}
      label={label}
      disabled={disabled}
      helperText={t(resolveNestedObject(name, errors)?.message) || helperText}
      error={resolveNestedObject(name, errors)}
    />
  )
  // @ts-ignore
  const inputComponent = (onBlur, onChange, value, ref) => (
    <Input
      {...options}
      id={name}
      noPlaceholder
      ref={ref}
      value={value}
      onChange={e => handleFieldChangeFromEvent(e, onChange)}
      onBlur={e => handleFieldBlurFromEvent(e, onBlur)}
      fullWidth
      // @ts-ignore
      type={type}
      label={label}
      disabled={disabled}
      helperText={t(resolveNestedObject(name, errors)?.message) || helperText}
      error={resolveNestedObject(name, errors)}
    />
  )
  // @ts-ignore
  const checkBoxComponent = (onChange, value, ref) => (
    <CheckBox
      id={name}
      ref={ref}
      // @ts-ignore
      checked={value}
      value={value}
      // @ts-ignore
      onChange={val => handleFieldChangeFromValue(val, onChange)}
      label={label}
      disabled={disabled}
      helperText={t(resolveNestedObject(name, errors)?.message) || helperText}
      error={resolveNestedObject(name, errors)}
    />
  )

  const textComponent = () => (
    <div style={{ display: 'flex', width: '100%' }}>
      <Typography variant="h1">{label}</Typography>
    </div>
  )
  // @ts-ignore
  const valueComponent = value => (
    // @ts-ignore
    <div style={options?.parentSx || {}}>
      <Typography {...options}>
        {
          // @ts-ignore
          options?.format ? options.format(value) : value
        }
      </Typography>
    </div>
  )
  const typographyComponent = () => (
    // @ts-ignore
    <div style={options?.parentSx || {}}>
      <Typography {...options}>{label}</Typography>
    </div>
  )

  // @ts-ignore
  const collisionDetectionComponent = () => <CollisionDetection disabled={disabled} />

  // @ts-ignore
  const smsButtonComponent = (onChange, value) => (
    <SMSButton
      mutation={mutation}
      options={queryOptions}
      label={label || ''}
      value={value}
      disabled={disabled}
      helperText={helperText}
      dialogContent={dialogContent}
    />
  )

  const billingButtonComponent = (value: any) => (
    <BillingButton
      mutation={mutation}
      options={queryOptions}
      value={value}
      label={label}
      disabled={disabled}
      callback={callback}
      helperText={helperText}
      onChangeProcessingId={onChangeProcessingId}
    />
  )
  // @ts-ignore
  const phoneInputComponent = (onBlur, onChange, value, ref) => (
    <Input
      {...options}
      id={name}
      noPlaceholder
      name={name}
      ref={ref}
      value={value}
      onChange={e => handleFieldChangeFromEvent(e, onChange)}
      onBlur={e => handleFieldBlurFromEvent(e, onBlur)}
      fullWidth
      // @ts-ignore
      type={type}
      label={label}
      disabled={disabled}
      helperText={t(resolveNestedObject(name, errors)?.message) || helperText}
      error={resolveNestedObject(name, errors)}
    />
  )
  // @ts-ignore
  const radioGroupComponent = (onChange, value, ref) => (
    <RadioButtonGroup
      ref={ref}
      id={name}
      // @ts-ignore
      value={value}
      // @ts-ignore
      onChange={e => handleFieldChangeFromValue(e, onChange)}
      label={label}
      disabled={disabled}
      items={radioItems}
      helperText={t(resolveNestedObject(name, errors)?.message) || helperText}
      error={resolveNestedObject(name, errors)}
      name={name}
    />
  )
  // @ts-ignore
  const uploadComponent = (onChange, value, ref) => {
    const files = getFilesFromValue(value)
    // @ts-ignore
    return <Uploader {...options} ref={ref} small value={files} disabled={disabled} name={name} />
  }
  // @ts-ignore
  const switchComponent = (onChange, value, ref) => (
    <Box sx={options?.parentSx}>
      <Switch
        id={name}
        {...options}
        ref={ref}
        // @ts-ignore
        onChange={e => handleSwitchChange(e, onChange)}
        label={label}
        small
        value={value}
        disabled={disabled}
        checked={value}
        defaultChecked={value}
        name={name}
        error={resolveNestedObject(name, errors)}
        helperText={t(resolveNestedObject(name, errors)?.message) || helperText}
      />
      {value && options?.children}
    </Box>
  )
  // @ts-ignore
  const renderCompanyAutoComplete = (onChange, value, ref) => (
    <CompanyAutoComplete
      label={label}
      query={query}
      queryOptions={queryOptions}
      onChange={e => handleFieldChangeFromValue(e, onChange)}
      ref={ref}
      onSelected={options?.onSelected}
      value={value}
      onSavePreset={options?.onSavePreset}
    />
  )

  if (type === 'actionButton') {
    return (
      <Button
        key={name || label || 'actionButton'}
        loading={options?.loading}
        startIcon={options?.icon}
        color="primary"
        onClick={options?.onClick}
        fullWidth
        disabled={disabled}
      >
        {label}
      </Button>
    )
  }
  if (type === 'export')
    return (
      <ExportButton label={label} disabled={disabled} helperText={helperText} options={options} />
    )

  if (type === 'requestCoverageButton')
    return (
      <RequestCoverageButton
        options={queryOptions}
        label={label || ''}
        disabled={disabled}
        helperText={helperText}
        dialogContent={dialogContent}
      />
    )
  if (type === 'forwardRightmartButton')
    return (
      <ForwardRightmartButton
        options={queryOptions}
        label={label || ''}
        disabled={disabled}
        helperText={helperText}
        dialogContent={dialogContent}
      />
    )
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onBlur, onChange, value, ref } }) => (
        <>
          {type === 'time' && dateComponent({ onChange, type, value }, ref)}
          {type === 'date' && dateComponent({ onChange, type, value }, ref)}
          {type === 'datetime' && dateComponent({ onChange, type, value }, ref)}
          {type === 'dropdown' && dropdownComponent(onChange, value, ref)}
          {type === 'select' && selectComponent(onChange, value, ref)}
          {/* {type === 'dropdown' && dropdownComponent({ onChange, value }, ref)} */}
          {type === 'typography' && typographyComponent()}
          {type === 'value' && valueComponent(value)}
          {
            // @ts-ignore
            type === 'text' && textComponent(onChange, value)
          }
          {type === 'billingButton' && billingButtonComponent(value)}
          {type === 'collisionDetection' && collisionDetectionComponent()}
          {type === 'smsButton' && smsButtonComponent(onChange, value)}
          {type === 'rating' && ratingComponent(onChange, value, ref)}
          {type === 'boolean' && booleanComponent(onChange, value, ref)}
          {type === 'textfield' && textfieldComponent(onBlur, onChange, value, ref)}
          {type === 'checkbox' && checkBoxComponent(onChange, value, ref)}
          {type === 'phone' && phoneInputComponent(onBlur, onChange, value, ref)}
          {type === 'radio' && radioGroupComponent(onChange, value, ref)}
          {type === 'switch' && switchComponent(onChange, value, ref)}
          {type === 'uploader' && uploadComponent(onChange, value, ref)}
          {type === 'files' && filesComponent(value)}
          {type === 'companySearch' && renderCompanyAutoComplete(onChange, value, ref)}
          {(type === 'money' || type === 'email' || type === 'default') &&
            inputComponent(onBlur, onChange, value, ref)}
        </>
      )}
    />
  )
}

FormItem.defaultProps = {
  query: undefined,
  mutation: undefined,
  queryOptions: undefined,
  label: '',
  disabled: false,
  type: 'default',
  helperText: undefined,
  dialogContent: undefined,
  options: {},
  radioItems: undefined,
  callback: undefined,
  onChangeProcessingId: undefined
}

export default React.memo(FormItem)
